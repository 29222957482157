import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

export const pageQuery = graphql`
  query BlogPostByPath($path: String!){
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    } 
  }
`

const Template = ({ data, location, pathContext }) => {
  const { markdownRemark: post } = data
  const { frontmatter, html } = post
  const { title } = frontmatter

  return (
    <div>
      <Helmet title={`${frontmatter.title} - My Blog`}/>
      <div>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }}/>
      </div>
    </div>
  )
}

export default Template